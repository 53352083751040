'use strict';

//EPM-2 - Monogramming
var progress = require('../../progress');
var accessibility = require('../../accessibility');

function showMonogramError($elem, $control) {
    var $monogramError = $elem.closest('.monogram-step').find('.monogram-error'),
        monogramErrorID = $monogramError.attr('id'),
        monogramAriaDescribedBy = $control.attr('aria-describedby') || '',
        nextMonogramAriaDescribedBy = $.trim(monogramAriaDescribedBy.indexOf(monogramErrorID) == -1 ? (monogramAriaDescribedBy + ' ' + $monogramError.attr('id')) : monogramAriaDescribedBy);

    $monogramError.removeClass('monogram-error-invisible');
    $control.addClass('invalid').attr('aria-describedby', nextMonogramAriaDescribedBy);
}

function handleSliders($thumbnailSlider, $mainSlider) {
    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobile'),
            enter: function () {
                $thumbnailSlider.slick('unslick').slick({
                    vertical: false,
                    slidesToShow: 3,
                    dots: false,
                    arrow: true,
                    focusOnSelect: true,
                    draggable: true,
                    swipe: true,
                    asNavFor: '.js-monogram-image-slider',
                    carouselRole: false,
                    carouselAriaLabel: false
                });

                $mainSlider.slick('unslick').slick({
                    slidesToShow: 1,
                    dots: false,
                    arrows: false,
                    draggable: true,
                    swipe: true,
                    asNavFor: '.js-monogram-thumbnail-slider',
                    carouselRole: false,
                    carouselAriaLabel: false
                });
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('tabletAndDesktop'),
            enter: function () {
                $thumbnailSlider.slick('unslick').slick({
                    vertical: true,
                    slidesToShow: 4,
                    dots: false,
                    arrow: true,
                    focusOnSelect: true,
                    draggable: true,
                    swipe: true,
                    asNavFor: '.js-monogram-image-slider',
                    carouselRole: false,
                    carouselAriaLabel: false
                });

                $mainSlider.slick('unslick').slick({
                    slidesToShow: 1,
                    dots: false,
                    arrows: false,
                    draggable: true,
                    swipe: true,
                    asNavFor: '.js-monogram-thumbnail-slider',
                    carouselRole: false,
                    carouselAriaLabel: false
                });
            }
        }
    ]);
}

function handleMonogramming ($wrapper, isPDP) {
    $wrapper.on('click', '.js-addMonogram, .js-modifyMonogram', function (e) {
        // modify from PDP will only update a form input value and some display
        // on the other hand, modify from cart page will update the monogram value in the basket

        e.preventDefault();

        var $this = $(this),
            isModify = $this.hasClass('js-modifyMonogram'),
            pid = $this.attr('data-pid');

        if (isPDP) { // PDP (add, modify)
            var $productOption = $this.closest('.monogram'),
                $select = $productOption.find('.product-option');

            if (isModify) {
                var $modifyLoader = $this.parents('.js-monogram-set').find('.js-monogram-progress-loader');

                window.vbqUtils.progressLoader(true, $modifyLoader);
            } else {
                progress.show($this);
            }

            $this.addClass('disabled');
        }
        else { // cart (modify only)
            var pliuuid = $this.attr('data-pliuuid'),
                $cartLoader = $this.parents('.js-monogram-detail').find('.js-cart-monogram-loader');

            window.vbqUtils.progressLoader(true, $cartLoader);
        }

        // show the modal
        $.ajax({
            url: window.Urls.showMonogramModal + '?pid=' + pid
        })
        .done(function (data) {
            // TODO error handling (invalid session or product)
            if (data.error) {
                return false;
            }

            $('#monogramDialog').remove(); // remove any previous dialog if present
            var $monogramDialog = $('<div/>').attr('id', 'monogramDialog').appendTo(window.vbqUtils.cache.$body);

            $monogramDialog.dialog({
                height: 'auto',
                width: 'auto',
                autoOpen: false,
                closeOnEscape: true,
                modal: true,
                overlay: {
                    opacity: 0.5,
                    background: 'black'
                },
                resizable: false,
                position: {
                    my: 'center',
                    at: 'center',
                    of: window,
                    collision: 'flipfit'
                },
                dialogClass : 'monogram-dialog',
                open: function (e) {
                    // EADA-40 - The modal container is not labeled as such: .dialog() case
                    window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                    window.vbqUtils.cache.$body.scrollTop(0); // does not work on iOS 13.1.2
                    window.vbqUtils.cache.$window.scrollTop(0);

                    // stop loader on cart page
                    if (!isPDP && $cartLoader) {
                        window.vbqUtils.progressLoader(false, $cartLoader);
                    }

                    var $thumbnailSlider = $('.js-monogram-thumbnail-slider'),
                        $mainSlider = $('.js-monogram-image-slider');

                    handleSliders($thumbnailSlider, $mainSlider);

                    // on opening the autofocus will put the cursor in an hidden field and it blocks the dialog closeOnEspace
                    $('.ui-dialog-titlebar-close').focus();

                    window.vbqUtils.cache.$document.on('click', '.ui-widget-overlay', function () {
                        $('.ui-dialog-titlebar-close').trigger('click');
                    });

                    // no need to reset the form as the modal is made from server data each time
                    var $monogramDialog = $('#monogramDialog'),
                        $monogramForm = $('#js-monogramForm'),
                        $monogramFontButtons = $monogramDialog.find('.js-monogramFontButton'),
                        $monogramFont = $('#js-monogramFont'),
                        $monogramColorButtons = $monogramDialog.find('.js-monogramColorButton'),
                        $monogramColor = $('#js-monogramColor'),
                        $monogramCharacters = $('#js-monogramCharacters'),
                        $monogramCharacterInputs = $monogramDialog.find('.js-monogramCharacter'),
                        $monogramOverlay = $('#js-monogramOverlay'),
                        $monogramPlacement = $('#js-monogramPlacement'),
                        $monogramPlacementButtons = $monogramDialog.find('.js-monogramPlacementButton'),
                        updateOverlay = function () {
                            var $selectedFont = $monogramFontButtons.filter('.selected'),
                                $selectedColor = $monogramColorButtons.filter('.selected');
                            if ($selectedFont.length && $selectedColor.length) {
                                $monogramOverlay.html($monogramCharacters.val()).attr('style', $selectedFont.attr('data-style') + $selectedColor.find('.monogram-color-inner').attr('style').replace('background-', ''));
                            }
                            else {
                                $monogramOverlay.html('');
                            }
                        };

                    // Init fake radio groups according to the ADA Compliance: font, color and placement radio groups
                    accessibility.fakeRadioGroupInit($monogramDialog);

                    $('#js-showOtherColors').on('click keydown', function (e) {
                        // mouse click or enter/space key
                        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                            // prevent the default action to stop scrolling when space is pressed
                            e.preventDefault();

                            $monogramColorButtons.filter('.hidden').removeClass('hidden');
                            $(this).remove();
                            $monogramColorButtons.attr('tabindex', '-1').attr('aria-checked', 'false').removeClass('selected')
                                .filter('.js-FirstOtherColor').attr('tabindex', '0').attr('aria-checked', 'true').addClass('selected').focus();

                            updateOverlay();
                        }
                    });

                    // set the submit button label
                    $('#js-monogramSubmit')
                        .find('.monogram-submit-add').toggleClass('hidden', isModify).end()
                        .find('.monogram-submit-modify').toggleClass('hidden', !isModify);

                    // modify monogram logic
                    if (isModify) {
                        // (Array(font, color, placement, text)).join(';')
                        var fullMonogram = isPDP ? $productOption.find('.js-monogram').val().split(';') : $this.attr('data-monogram').split(';'),
                            font = fullMonogram[0],
                            color = fullMonogram[1],
                            placement = fullMonogram[2],
                            characters = fullMonogram[3];

                        $monogramFontButtons.each(function () {
                            var $button = $(this);

                            if ($button.attr('data-value') == font) {
                                $button.trigger('setActiveRadioState', [{isFocus: false, isCallback: false}]);

                                return false; // break
                            }
                        });
                        $monogramFont.val(font);

                        $monogramColorButtons.each(function () {
                            var $button = $(this);

                            if ($button.attr('data-value') == color) {
                                $button.trigger('setActiveRadioState', [{isFocus: false, isCallback: false}]);

                                return false; // break
                            }
                        });
                        $monogramColor.val(color);

                        $monogramPlacementButtons.each(function () {
                            var $button = $(this);
                            if ($button.attr('data-value') == placement) {
                                $button.trigger('setActiveRadioState', [{isFocus: false, isCallback: false}]);

                                return false; // break
                            }
                        });
                        $monogramPlacement.val(placement);

                        $monogramCharacterInputs.each(function (i, elem) {
                            var $input = $(elem);
                            if (characters.length >= i + 1) {
                                $input.val(characters[i]).addClass('filled');
                            }
                            else {
                                return false; // break
                            }
                        });
                        $monogramCharacters.val(characters);

                        updateOverlay();
                    }

                    // listeners and logic for form inputs
                    $monogramFontButtons.on('afterFakeRadioStateUpdated', function () {
                        var $monogramFontStep = $monogramFont.closest('.monogram-step'),
                            $monogramFontGroup = $monogramFontStep.find('.js-ada-radio-group'),
                            $monogramFontError = $monogramFontStep.find('.monogram-error');

                        $monogramFont.val($monogramFontButtons.filter('.selected').attr('data-value'));
                        $monogramFontGroup.removeClass('invalid').removeAttr('aria-describedby');
                        $monogramFontError.addClass('monogram-error-invisible');
                        updateOverlay();

                        /* carousel navigation becomes bugged when slickFilter is used, with focusOnSelect slick option
                        // filter images
                        if ($mainSlider.hasClass('slick-initialized')) {
                            $mainSlider.slick('slickUnfilter').slick('slickFilter', ':first, .js-' + $this.attr('data-forFilter'));
                            $mainSlider.slick('slickGoTo', 0);
                        }
                        if ($thumbnailSlider.hasClass('slick-initialized')) {
                            $thumbnailSlider.slick('slickUnfilter').slick('slickFilter', ':first, .js-' + $this.attr('data-forFilter'));
                            $thumbnailSlider.slick('slickGoTo', 0);
                        }
                        */
                    });

                    $monogramColorButtons.on('afterFakeRadioStateUpdated', function () {
                        var $monogramColorStep = $monogramColor.closest('.monogram-step'),
                            $monogramColorGroup = $monogramColorStep.find('.js-ada-radio-group'),
                            $monogramColorError = $monogramColorStep.find('.monogram-error');

                        $monogramColor.val($monogramColorButtons.filter('.selected').attr('data-value'));
                        $monogramColorGroup.removeClass('invalid').removeAttr('aria-describedby');
                        $monogramColorError.addClass('monogram-error-invisible');
                        updateOverlay();
                    });

                    // keyup for physical keyboard, touchend for virtual one (mobile and tablet devices)
                    var errorTimeout;
                    $monogramCharacterInputs.on('keyup touchend', function (e) {
                        var $this = $(this),
                            v = $this.val();

                        if (errorTimeout) {
                            window.clearTimeout(errorTimeout);
                            $this.removeClass('invalid')
                                .closest('.monogram-step').find('.monogram-help').removeClass('invalid');
                        }

                        if (v !== '') {
                            if(!this.checkValidity() || v.length !== 1) { // checkValidity will test the pattern regular expression
                                v = '';
                                $this.addClass('invalid')
                                    .closest('.monogram-step').find('.monogram-help').addClass('invalid');
                                $this.val('');
                                errorTimeout = window.setTimeout(function () {
                                    $this.removeClass('invalid')
                                        .closest('.monogram-step').find('.monogram-help').removeClass('invalid');
                                }, 500);
                            }
                            else {
                                $this.removeClass('invalid')
                                    .closest('.monogram-step').find('.monogram-help').removeClass('invalid');
                            }
                        }

                        $this.toggleClass('filled', v !== '');

                        if (v.length == this.maxLength) {
                            var $next = $this.next('.js-monogramCharacter');
                            if ($next.length) {
                                $next.focus();
                            }
                            else {
                                $this.blur();
                            }
                        }

                        $monogramCharacters.val(
                            $monogramCharacterInputs.map(function () { return $.trim(this.value || ''); }).get().join('')
                        );

                        updateOverlay();

                        var $monogramCharactersError = $monogramCharacters.closest('.monogram-step').find('.monogram-error'),
                            monogramCharactersAriaDescribedBy = $monogramCharacterInputs.first().attr('aria-describedby') || '';

                        $monogramCharactersError.addClass('monogram-error-invisible');
                        $monogramCharacterInputs.removeClass('invalid');
                        $monogramCharacterInputs.attr('aria-describedby', $.trim(monogramCharactersAriaDescribedBy.replace($monogramCharactersError.attr('id'), '')));
                    });

                    $monogramPlacementButtons.on('afterFakeRadioStateUpdated', function () {
                        var $monogramPlacementStep = $monogramPlacement.closest('.monogram-step'),
                            $monogramPlacementGroup = $monogramPlacementStep.find('.js-ada-radio-group'),
                            $monogramPlacementError = $monogramPlacementStep.find('.monogram-error');

                        $monogramPlacement.val($monogramPlacementButtons.filter('.selected').attr('data-value'));
                        $monogramPlacementGroup.removeClass('invalid').removeAttr('aria-describedby');
                        $monogramPlacementError.addClass('monogram-error-invisible');
                        updateOverlay();
                    });

                    // form submit logic
                    $monogramForm.on('submit', function (e) {
                        var $this = $(this);

                        e.preventDefault();

                        // force characters input refresh, security
                        $monogramCharacters.val(
                            $monogramCharacterInputs.map(function () { return $.trim(this.value || ''); }).get().join('')
                        );

                        // form validation logic
                        var font = $monogramFont.val(),
                            color = $monogramColor.val(),
                            characters = $monogramCharacters.val(),
                            placement = $monogramPlacement.val(),
                            hasError = false,
                            $focusElement = $();

                        // The first option in the monogram interface
                        if (font === '' || font === null) {
                            showMonogramError($monogramFont, $monogramFontButtons.closest('.js-ada-radio-group'));
                            hasError = true;
                            $focusElement = $monogramFontButtons.filter('[tabindex="0"]');
                        }

                        // The second option in the monogram interface
                        if (color === '' || color === null) {
                            showMonogramError($monogramColor, $monogramColorButtons.closest('.js-ada-radio-group'));
                            hasError = true;

                            if (!$focusElement.length) {
                                $focusElement = $monogramColorButtons.filter('[tabindex="0"]');
                            }
                        }

                        // The third option in the monogram interface
                        if (characters === '' || characters === null || (new RegExp('^[A-Za-z+.]{1,3}$', 'g')).test(characters) == false) {
                            showMonogramError($monogramCharacters, $monogramCharacterInputs);
                            hasError = true;

                            if (!$focusElement.length) {
                                $focusElement = $monogramCharacterInputs.first();
                            }
                        }

                        // The forth option in the monogram interface
                        if (placement === '' || placement === null) {
                            showMonogramError($monogramPlacement, $monogramPlacementButtons.closest('.js-ada-radio-group'));
                            hasError = true;

                            if (!$focusElement.length) {
                                $focusElement = $monogramPlacementButtons.filter('[tabindex="0"]');
                            }
                        }

                        if (hasError) {
                            // EADA23-5 ADA Focus on the first invalid field on the form if error message is displayed
                            if ($focusElement.length) {
                                $focusElement.focus();
                            }

                            return false;
                        }

                        // (Array(font, color, placement, text)).join(';')
                        var fullMonogram = [font, color, placement, characters].join(';');
                        progress.show($this.find($('#js-monogramSubmit')));

                        // Set the product's monogram in the session
                        $.ajax({
                            url: window.Urls.setMonogram + '?pid=' + pid + '&monogram=' + fullMonogram
                        }).then(function (response) {
                            // No response necessary
                        });

                        if (isPDP) {
                            $productOption.find('.js-monogram').val(fullMonogram);
                            // Force the monogram select option
                            $select.prop('selectedIndex', 1);

                            // Update the display
                            $productOption
                                .find('.monogram-set').removeClass('visually-hidden')
                                    .find('.js-monogramValue').text(characters).end()
                                .end()
                                .find('.monogram-not-set').addClass('visually-hidden');

                            // Update the PDP price, same logic is present in variant.js for the product content update on page load and variation change
                            var optionPrice = $productOption.find('.monogram-not-set').find('.price-display-component').attr('data-pricevalue'),
                                $productPrice = $wrapper.find('.product-detail-price').find('.price-display-component'); // normal and sticky form prices
                            $productPrice.attr('data-originalpricevalue', $productPrice.attr('data-pricevalue'));
                            $productPrice.attr('data-pricevalue', parseFloat($productPrice.attr('data-pricevalue')) + parseFloat(optionPrice));
                            $productPrice.addClass('js-forcedPriceDisplay'); // for non converted prices (see pricedisplay.js)
                            window.vbqUtils.priceDisplay.refresh();

                            // hide the Apple Pay button if present
                            $('.dw-apple-pay-button').addClass('hidden');

                            // hide the same day delivery buttons and messages if present (clickAndCollect and ultraFastDelivery cases)
                            $('.js-sameDayDeliveryOption').addClass('hidden');
                            // show the "same day delivery not possible with monogram" message
                            $('.js-sameDayDeliveryOptionNotPossible').removeClass('hidden');

                            $monogramDialog.dialog('close');
                        }
                        else {
                            // Set the product's monogram in the session
                            $.ajax({
                                url: window.Urls.updateMonogram + '?pliuuid=' + pliuuid + '&monogram=' + fullMonogram
                            }).then(function (response) {
                                // No response necessary
                                $monogramDialog.dialog('close');

                                window.location.reload();
                            });
                        }
                    });

                    // Setup thumbnail carousel
                    if ($thumbnailSlider.length && !$thumbnailSlider.hasClass('slick-initialized')) {
                        $thumbnailSlider.slick({
                            vertical: true,
                            slidesToShow: 4,
                            dots: false,
                            arrow: false,
                            asNavFor: '.js-monogram-image-slider',
                            focusOnSelect: true,
                            draggable: true,
                            swipe: true,
                            adaptiveHeight: true,
                            carouselRole: false,
                            carouselAriaLabel: false
                        });
                        $mainSlider.slick({
                            slidesToShow: 1,
                            dots: false,
                            arrow: false,
                            draggable: false,
                            swipe: false,
                            carouselRole: false,
                            carouselAriaLabel: false
                        });
                    }
                },
                close: function () {
                    // activate body scroll
                    window.vbqUtils.cache.$body.css('overflow', '');
                    window.vbqUtils.cache.$document.off('viewportchange').off('click', '.ui-widget-overlay');

                    window.setTimeout(function() {
                        // give back the focus, not to the button since it will be hidden
                        var $div = $this.closest('.product-options');
                        if ($div.length) { // only for PDP, not needed on cart page as it reloads
                            $div.focus();
                            // scroll back to the monogram block
                            window.vbqUtils.cache.$window.scrollTop($div.offset().top - 200);
                        }
                    }, 50);
                }
            });

            $monogramDialog.append(data);
            if (isModify) {
                $('#js-monogramModalTitle').find('.js-add').addClass('hidden').end().find('.js-modify').removeClass('hidden');
            }
            if (window.vbqUtils.breakpoint.is('fromDesktop')) {
                window.vbqUtils.cache.$body.css('overflow', 'hidden');
            }
            $monogramDialog.dialog('open');

            // overflow hidden on mobile has no effect (iOS), need to scroll the viewport
            if (window.vbqUtils.breakpoint.is('mobile')) {
                window.vbqUtils.cache.$body.scrollTop($monogramDialog.offset().top - 1);
            }

            $this.removeClass('disabled');
        })
        .fail(function () {
            window.vbqUtils.cache.$body.css('overflow', '');
            $this.removeClass('disabled');
        })
        .always(function () {
            if (isPDP && isModify) {
                window.vbqUtils.progressLoader(false, $modifyLoader);
            } else {
                progress.hide();
            }
        });
    });

    // EPM-2 - Monogramming - Remove the stored monogram
    $wrapper.on('click', '.js-removeStoredMonogram', function (e) {
        e.preventDefault();

        var $this = $(this),
            $productOption = $this.closest('.monogram'),
            $select = $productOption.find('.product-option'),
            pid = $this.attr('data-pid');

        // Force the monogram select option
        $select.prop('selectedIndex', 0);
        $productOption.find('.js-monogram').val('');

        // Set the product's monogram in the session
        $.ajax({
            url: Urls.setMonogram + '?pid=' + pid + '&remove=1'
        }).then(function (response) {
            // reload add-to-cart form for current size
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                $wrapper.find('.js-size-list').find('.js-size-radio').filter('.selected').trigger('change');
            }
            else {
                $wrapper.find('.js-size-select').trigger('change');
            }
        });

        // Update the display
        $productOption
            .find('.monogram-set').addClass('visually-hidden')
                .find('.js-monogramValue').text('').end()
            .end()
            .find('.monogram-not-set').removeClass('visually-hidden');

        // show the Apple Pay button && click and collect button if present
        $('.js-product-btn-options').removeClass('hidden');

        // Update the PDP price
        /*
        $wrapper.find('.product-detail-price').find('.option-price').remove();
        */
        var $productPrice = $wrapper.find('.product-detail-price').find('.price-display-component');
        $productPrice.attr('data-pricevalue', $productPrice.attr('data-originalpricevalue'));
        $productPrice.removeAttr('data-originalpricevalue');
        $productPrice.addClass('js-forcedPriceDisplay'); // for non converted prices (see pricedisplay.js)
        window.vbqUtils.priceDisplay.refresh();
    });
}

exports.handleMonogramming = handleMonogramming;
